import championAggregates from "@/feature-lol-rust-be/champion-aggregates.mjs";
import match from "@/feature-lol-rust-be/match.mjs";
import matchTimeline from "@/feature-lol-rust-be/match-timeline.mjs";
import matchlist from "@/feature-lol-rust-be/matchlist.mjs";
import playstyles from "@/feature-lol-rust-be/playstyles.mjs";
import postPostmatchLP from "@/feature-lol-rust-be/post-postmatch-lp.mjs";
import postRankUpdate from "@/feature-lol-rust-be/post-rank-update.mjs";
import proMatchHistory from "@/feature-lol-rust-be/pro-match-history.mjs";
import profile from "@/feature-lol-rust-be/profile.mjs";
import profileRanks from "@/feature-lol-rust-be/profile-ranks.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { appLog } from "@/util/dev.mjs";

export function setup() {
  /* 
    Why are we using an object instead of just conditionally enabling this entire module?
    During the rollout of the new player APIs, we want to have the player endpoints enabled
    by the flag, but always use the new mutations new mutation, even if theyre not using
    new endpoints. When the endpoints are enabled we turn off the old mutations.
  */

  const playerAPIsEnabled = true;
  const mutationsEnabled = true;

  lolRefs.rustBE = {
    playerAPIsEnabled,
    mutationsEnabled,
  };

  if (playerAPIsEnabled) {
    appLog("[LoL] [PLAYER API] 🦀 Enabled");
    API.PLAYER.getProfile = profile;
    API.PLAYER.getProfileRanks = profileRanks;
    API.PLAYER.getMatchList = matchlist;
    API.PLAYER.getMatch = match;
    API.PLAYER.getPlaystyles = playstyles;
    API.PLAYER.getChampionAggregates = championAggregates;
    API.PLAYER.getMatchTimeline = matchTimeline;
  } else {
    appLog("[LoL] [PLAYER API] ⚗️ Enabled");
    API.PLAYER.getPlaystylesPreSeed_DEPRECATE_SOON = playstyles;
    API.PLAYER.getChampionAggregatesPreSeed_DEPRECATE_SOON = championAggregates;
  }

  if (mutationsEnabled) {
    appLog("[LoL] [UPDATE API] 🦀 Enabled");
    API.PLAYER.postPostmatchLP = postPostmatchLP;
    API.PLAYER.postRankUpdate = postRankUpdate;

    // Force enable probuilds
    API.PROBUILDS.getProMatchHistory = proMatchHistory;

    if (playerAPIsEnabled) {
      API.PLAYER.postPostmatchLPElixir_DEPRECATE_SOON = () => Promise.resolve();
      API.PLAYER.postRankUpdateElixir_DEPRECATE_SOON = () => Promise.resolve();
    }
  } else {
    appLog("[LoL] [UPDATE API] ⚗️ Enabled");
  }
}

export function teardown() {
  // TODO: teardown and restore originals?
  lolRefs.rustBE = {
    playerAPIsEnabled: false,
    mutationsEnabled: true,
  };
}
